import { Button, Image, Input, Modal, Select, Table, Upload } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  addCastToMovie,
  addCastToSeries,
  deleteEpisode,
  deleteMovie,
  deleteSeries,
  deleteSoon,
  removeMovieCast,
  removeSeriesCast,
  updateMovie,
  updateSeries,
  updateSoon,
} from "../../utils/api";
import { isEnable } from "../common/isEnabled";
import useSearch from "../common/Search";
import CustomSelect from "../common/CustomSelect";

const ContentTable = ({
  data,
  loading,
  episodes,
  setUpdate,
  setModal2,
  messageApi,
  reload,
  series,
  id,
  coming,
}) => {
  const navigate = useNavigate();
  const search = useSearch();
  const [modal, setModal] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [cast, setCast] = React.useState({});
  const [castId, setCastId] = React.useState("");
  const [removeCast, setRemoveCast] = React.useState({});
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  console.log(removeCast);
  const addCast = async () => {
    setLoading2(true);

    const formData = new FormData();
    formData.append("image", cast.image);
    formData.append("name", cast.name);
    formData.append("role", cast.role);
    const [success, response] = series
      ? await addCastToSeries(castId, formData)
      : await addCastToMovie(castId, formData);
    if (success) {
      messageApi.success("Cast added successfully");
      setModal(false);
      reload();
    }
    setLoading2(false);
  };
  const removeCastHandler = async () => {
    setLoading2(true);

    if (!removeCast.cid) {
      messageApi.error("Please select a cast");
      return;
    }
    const [success, response] = series
      ? await removeSeriesCast({
          seriesId: removeCast?.id,
          castId: removeCast?.cid,
        })
      : await removeMovieCast({
          movieId: removeCast?.id,
          castId: removeCast?.cid,
        });
    if (success) {
      messageApi.success("Cast removed successfully");
      setModal3(false);
      reload();
    }
    setLoading2(false);
  };
  const url = coming
    ? []
    : !series
    ? [
        {
          title: "Url",
          dataIndex: "url",
          key: "url",
          render: (text, record) => (
            <Link to={`/video`} state={{ url: record.url }}>
              Link
            </Link>
          ),
        },
        {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
        },
      ]
    : [
        {
          title: "Episode",
          dataIndex: "episode",
          key: "episode",
          render: (text, record) => (
            <Button
              type="primary"
              onClick={() => {
                navigate(`/episodes/${record._id}`);
              }}
            >
              Episodes
            </Button>
          ),
        },
      ];

  const url2 = coming
    ? [
        isEnable,

        {
          title: "Rating",
          dataIndex: "rating",
          key: "rating",
        },
        {
          title: "Language",
          dataIndex: "language",
          key: "language",
        },
        {
          title: "Genres",
          dataIndex: "genres",
          key: "genres",
          render: (text, record) =>
            text?.map((item) => <span>{item.name}, </span>),
        },
        {
          title: "Trailer",
          dataIndex: "trailer",
          key: "trailer",
          render: (text, record) => (
            <Link to={`/video`} state={{ url: text }}>
              Link
            </Link>
          ),
        },
      ]
    : !episodes
    ? [
        isEnable,

        {
          title: "Rating",
          dataIndex: "rating",
          key: "rating",
        },
        {
          title: "Language",
          dataIndex: "language",
          key: "language",
        },
        {
          title: "Genres",
          dataIndex: "genres",
          key: "genres",
          render: (text, record) =>
            text?.map((item) => <span>{item.name}, </span>),
        },
        {
          title: "Trailer",
          dataIndex: "trailer",
          key: "trailer",
          render: (text, record) => (
            <Link to={`/video`} state={{ url: text }}>
              Link
            </Link>
          ),
        },
        {
          title: "Cast",
          dataIndex: "cast",
          key: "cast",
          render: (text, record) =>
            text?.map((item) => <span>{item.name}, </span>),
        },
      ]
    : [];
  console.log(data);
  return (
    <>
      <Table
        rowKey={(record) => record._id}
        scroll={{ x: 1000 }}
        dataSource={episodes ? data : data?.reverse()}
        loading={loading}
        columns={[
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
              <div className="col2">
                <Button
                  type="primary"
                  onClick={() => {
                    setModal2(true);
                    setUpdate(record);
                  }}
                >
                  Update
                </Button>
                {!episodes && (
                  <>
                    {!coming && (
                      <>
                        <Button
                          type="primary"
                          onClick={() => {
                            setModal(true);
                            setCastId(record._id);
                          }}
                        >
                          Add Cast
                        </Button>{" "}
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            setModal3(true);
                            setRemoveCast({
                              cast: record.cast,
                              id: record._id,
                            });
                          }}
                        >
                          Remove Cast
                        </Button>{" "}
                      </>
                    )}
                    <Button
                      loading={loading3}
                      type="primary"
                      danger={record.isEnable}
                      onClick={async () => {
                        setLoading3(true);
                        const data = {
                          isEnable: !record.isEnable,
                        };
                        const [success, response] = coming
                          ? await updateSoon(record._id, data)
                          : series
                          ? await updateSeries(record._id, data)
                          : await updateMovie(record._id, data);
                        if (success) {
                          messageApi.success(" updated successfully");
                          reload();
                        }
                        setLoading3(false);
                      }}
                    >
                      {record.isEnable ? "Disable" : "Enable"}
                    </Button>
                  </>
                )}
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure you want to delete this?",
                      onOk: async () => {
                        const [success, response] = coming
                          ? await deleteSoon(record._id)
                          : series
                          ? await deleteSeries(record._id)
                          : episodes
                          ? await deleteEpisode({
                              seriesId: id,
                              episodeId: record._id,
                            })
                          : await deleteMovie(record._id);
                        if (success) {
                          messageApi.success(`
                        ${
                          series ? "Series" : episodes ? "Episode" : "Movie"
                        } deleted successfully`);
                          reload();
                        }
                      },
                    });
                  }}
                >
                  Delete
                </Button>
              </div>
            ),
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...search("name"),
          },

          {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text, record) => (
              <div style={{ width: 300, overflow: "hidden" }}>
                {text.slice(0, 100)}
              </div>
            ),
          },
          ...url,
          ...url2,
          {
            title: "Horizonal-Banner",
            dataIndex: "horizontalBanner",
            key: "horizonalBanner",
            render: (text, record) => (
              <Image
                src={text}
                width={100}
                height={100}
                alt="horizonalBanner"
              />
            ),
          },
          {
            title: "Vertical-Banner",
            dataIndex: "verticalBanner",
            key: "verticalBanner",
            render: (text, record) => (
              <Image src={text} width={100} height={100} alt="verticalBanner" />
            ),
          },
        ]}
      />
      <Modal
        open={modal}
        onCancel={() => setModal(false)}
        title="Add Cast"
        onOk={addCast}
        confirmLoading={loading2}
      >
        <div className="col2">
          <Input
            placeholder="Name"
            onChange={(e) => {
              setCast((prev) => ({
                ...prev,
                name: e.target.value,
              }));
            }}
          />
          <Input
            placeholder="Role"
            onChange={(e) => {
              setCast((prev) => ({
                ...prev,
                role: e.target.value,
              }));
            }}
          />
          <Upload
            onChange={(info) => {
              setCast((prev) => ({
                ...prev,
                image: info.file.originFileObj,
              }));
            }}
          >
            <Button>Upload Image</Button>
          </Upload>
        </div>
      </Modal>
      <Modal
        open={modal3}
        onCancel={() => setModal3(false)}
        title="Remove Cast"
        onOk={removeCastHandler}
        confirmLoading={loading2}
      >
        <CustomSelect
          name={"Cast"}
          options={removeCast?.cast?.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          value={removeCast?.cid}
          onChange={(e) => {
            console.log(e);
            setRemoveCast((prev) => ({
              ...prev,
              cid: e,
            }));
          }}
        />
      </Modal>
    </>
  );
};

export default ContentTable;
