import {
  Button,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Table,
  message,
} from "antd";
import React, { useEffect } from "react";
import {
  addDatatoRent,
  getAllMovies,
  getAllSeries,
  getAllUserRent,
  getAllUsers,
} from "../utils/api";
import useSearch from "../components/common/Search";
const Rent = () => {
  const [loading, setLoading] = React.useState(true);
  const [rent, setRent] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [updateDetails, setUpdateDetails] = React.useState({});
  const [dataH, setData] = React.useState([]);
  const getallrents = async (id) => {
    const [success, data] = await getAllUserRent(id);
    console.log(success, data);
    if (success) {
      setRent(data);
    }
  };

  const getAllUsersHandler = async () => {
    const [success, data] = await getAllUsers();
    if (success) {
      setUsers(data);
    }
  };
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const getallMovies = async () => {
    const [success, data] = await getAllMovies();
    if (success) {
      setData(data);
    }
  };
  const search = useSearch();
  const getallSeries = async () => {
    const [success, data] = await getAllSeries();
    if (success) {
      setData(data);
    }
  };

  const addRented = async () => {
    if (updateDetails.type && updateDetails.itemId && updateDetails.userId) {
      console.log(updateDetails);
      const formData = {
        userId: updateDetails.userId,
        type: updateDetails.type,
        [updateDetails.type === "Movie" ? "movieId" : "seriesId"]:
          updateDetails.itemId,
      };

      const [success, data] = await addDatatoRent(formData);
      if (success) {
        messageApi.success("Rented successfully");
        setOpen2(false);
      } else {
        messageApi.error("Rented error");
      }
    } else {
      messageApi.error("Rented error");
    }
  };

  useEffect(() => {
    if (updateDetails.type === "Movie") {
      getallMovies();
    }
    if (updateDetails.type === "Series") {
      getallSeries();
    }
  }, [updateDetails.type]);

  useEffect(() => {
    getAllUsersHandler();
  }, []);

  return (
    <div>
      <Table
        dataSource={users}
        //   head={["Image", "name", "email", "PhoneNumber", "Rented", "Actions"]}
        //   rows={(user) => (
        //     <>
        //       <Td>
        //         <Image
        //           src={user.image}
        //           alt={user.name}
        //           sx={{
        //             width: "50px",
        //             height: "50px",
        //             borderRadius: "50%",
        //           }}
        //         />
        //       </Td>

        //       <Td>{user.name}</Td>
        //       <Td>{user.email}</Td>
        //       <Td>{user.phoneNumber}</Td>
        //       <Td>
        //         <Button
        //           colorScheme={"blue"}
        //           onClick={() => {
        //             getallrents(user._id);
        //             onOpen();
        //           }}
        //         >
        //           View
        //         </Button>
        //       </Td>
        //       <Td>
        //         <Button
        //           colorScheme={"blue"}
        //           onClick={() => {
        //             setUpdateDetails((prev) => ({ ...prev, userId: user._id }));
        //             onOpen2();
        //           }}
        //         >
        //           Add
        //         </Button>
        //       </Td>
        //     </>
        //   )}

        columns={[
          {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (text) => <Image src={text} alt="image" width={50} />,
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...search("name"),
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
          },
          {
            title: "Rented",
            dataIndex: "rented",
            key: "rented",
            render: (text, record) => (
              <Button
                type="primary"
                onClick={async () => {
                  const [success, data] = await getAllUserRent(record._id);
                  console.log(success, data);
                  Modal.info({
                    title: "Rented",
                    width: 600,
                    content: (
                      <Table
                        dataSource={data?.rentedContent}
                        columns={[
                          {
                            title: "Image",
                            dataIndex: "item",
                            key: "item",
                            render: (text) => (
                              <Image
                                src={text?.horizontalBanner}
                                alt="image"
                                width={50}
                              />
                            ),
                          },
                          {
                            title: "Name",
                            dataIndex: "item",
                            key: "item",
                            render: (text) => <p>{text?.name}</p>,
                          },
                          {
                            title: "Expire Date",
                            dataIndex: "expireDate",
                            key: "expireDate",
                          },
                        ]}
                      />
                    ),
                    onOk() {},
                  });
                }}
              >
                View
              </Button>
            ),
          },
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
              <Button
                type="primary"
                onClick={() => {
                  setUpdateDetails((prev) => ({ ...prev, userId: record._id }));
                  setOpen2(true);
                }}
              >
                Add
              </Button>
            ),
          },
        ]}
      />

      <Modal
        title="Add Rent"
        open={open2}
        onCancel={() => setOpen2(false)}
        onOk={addRented}
        okText="Add"
        cancelText="Cancel"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Select
            placeholder="Select type"
            options={[
              { label: "Movie", value: "Movie" },
              { label: "Series", value: "Series" },
            ]}
            onChange={(e) => {
              setUpdateDetails((prev) => ({ ...prev, type: e }));
            }}
            style={{
              width: "100%",
            }}
          />
          {updateDetails.type && (
            <Select
              style={{
                width: "100%",
              }}
              placeholder={`Select ${updateDetails?.type}`}
              onChange={(e) => {
                setUpdateDetails((prev) => ({
                  ...prev,
                  itemId: e,
                }));
              }}
              options={dataH.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
            ></Select>
          )}
        </Space>
      </Modal>

      {/* <CreateModal table title="Rented" isOpen={isOpen} onClose={onClose}>
        <CreateTable
          data={rent.rentedContent}
          head={["Image", "name", "Expire Date"]}
          rows={(rented) => (
            <>
              <Td>
                <Image
                  src={rented?.item?.horizontalBanner}
                  alt={rented?.item?.name}
                  sx={{ height: "100px", width: "100px" }}
                />
              </Td>
              <Td>{rented?.item?.name}</Td>
              <Td>{rented?.expireDate}</Td>
            </>
          )}
        />
      </CreateModal>
      <CreateModal
        title="Add Rent"
        isOpen={isOpen2}
        onClose={onClose2}
        create={addRented}
      >
        <Select
          placeholder="Select type"
          onChange={(e) => {
            setUpdateDetails((prev) => ({ ...prev, type: e.target.value }));
          }}
        >
          <option value="Movie">Movie</option>
          <option value="Series">Series</option>
        </Select>
        {updateDetails.type && (
          <Select
            placeholder={`Select ${updateDetails?.type}`}
            onChange={(e) => {
              setUpdateDetails((prev) => ({ ...prev, itemId: e.target.value }));
            }}
          >
            {dataH.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </Select>
        )}
      </CreateModal> */}
    </div>
  );
};

export default Rent;
