import { Button, Input, message, Select, Table } from "antd";
import Modal from "antd/es/modal/Modal";
import React from "react";
import { useParams } from "react-router-dom";
import Head from "../components/common/Head";
import ContentModal from "../components/subcategories/ContentModal";
import SubModal from "../components/subcategories/SubModal";
import useValidate from "../hooks/useValidate";
import {
  addMovietoSubCategory,
  addSeriesToSubCategory,
  addSubCategory,
  deleteSubCategory,
  getAllMovies,
  getAllSeries,
  getSubCategories,
  updateSubCategory,
} from "../utils/api";

const SubCategories = () => {
  const { id, name } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [subCategories, setSubCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [update, setUpdate] = React.useState(null);
  const [newSubCategory, setNewSubCategory] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [content, setContent] = React.useState([]);
  const [contentid, setContentId] = React.useState(null);
  const [modal3, setModal3] = React.useState(false);
  const [movies, setMovies] = React.useState([]);
  const [series, setSeries] = React.useState([]);

  const [add, setAdd] = React.useState({});

  const addDataToSubCategory = async () => {
    if (add.position === undefined) {
      messageApi.error("Please enter position");
      return;
    }
    setButtonLoading(true);
    if (add.type === "movie") {
      const [success, response] = await addMovietoSubCategory({
        movieId: add.id,
        subcategoryId: add.sub,
        position: add.position,
      });

      if (success) {
        messageApi.success("Movie added successfully");
        getSubCategoriesData();
      }
    } else {
      const [success, response] = await addSeriesToSubCategory({
        seriesId: add.id,
        subcategoryId: add.sub,
        position: add.position,
      });

      if (success) {
        messageApi.success("Series added successfully");
        getSubCategoriesData();
      }
    }
    setAdd({});
    setModal3(false);
    setButtonLoading(false);
  };

  const getSubCategoriesData = async () => {
    setLoading(true);
    const [success, response] = await getSubCategories(id);
    if (success) setSubCategories(response);
    setLoading(false);
  };

  const getAllMoviesData = async () => {
    setLoading(true);
    const [success, response] = await getAllMovies();
    if (success) setMovies(response);
    setLoading(false);
  };

  const getAllSeriesData = async () => {
    setLoading(true);
    const [success, response] = await getAllSeries();
    if (success) setSeries(response);
    setLoading(false);
  };
  const delSubCategory = async (id) => {
    setLoading(true);
    const [success, response] = await deleteSubCategory(id);
    if (success) {
      messageApi.success("Sub Category deleted successfully");
      getSubCategoriesData();
    }
    setLoading(false);
  };
  console.log(add);
  useValidate();
  React.useEffect(() => {
    getSubCategoriesData();
    getAllMoviesData();
    getAllSeriesData();
  }, []);
  return (
    <>
      {contextHolder}
      <Head
        title={name}
        button="Add Sub Category"
        onClick={() => {
          setNewSubCategory({
            position: subCategories.length + 1,
          });
          setUpdate(null);
          setModal(true);
        }}
      />
      <Table
        loading={loading}
        dataSource={subCategories}
        scroll={{ x: 1000 }}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Position",
            dataIndex: "position",
            key: "position",
          },
          {
            title: "Image Type",
            dataIndex: "imageType",
            key: "imageType",
          },
          {
            title: "Content",
            dataIndex: "content",
            key: "content",
            render: (text, record) => (
              <Button
                type="primary"
                onClick={() => {
                  setContent(record.content);
                  setContentId(record._id);
                  setModal2(true);
                }}
              >
                View
              </Button>
            ),
          },
          {
            title: "Add Content",
            dataIndex: "addContent",
            key: "addContent",
            render: (text, record) => (
              <div className="col2">
                <Button
                  type="primary"
                  onClick={() => {
                    setAdd({
                      type: "series",
                      sub: record._id,
                      content: record.content,
                      position: record.position,
                    });
                    setModal3(true);
                  }}
                >
                  Add Series
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setAdd({
                      type: "movie",
                      sub: record._id,
                      content: record.content,
                      position: record.position,
                    });
                    setModal3(true);
                  }}
                >
                  Add Movie
                </Button>
              </div>
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
              <div className="col2">
                <Button
                  type="primary"
                  onClick={() => {
                    setNewSubCategory({
                      name: record.name,
                      position: record.position,
                      imageType: record.imageType,
                    });
                    setUpdate(record._id);
                    setModal(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  danger
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure delete this SubCategories?",
                      okText: "Yes",
                      okType: "danger",
                      cancelText: "No",
                      onOk() {
                        delSubCategory(record._id);
                      },
                    });
                  }}
                >
                  Delete
                </Button>
              </div>
            ),
          },
        ]}
      />

      <ContentModal
        modal2={modal2}
        setModal2={setModal2}
        content={content}
        id={contentid}
        reload={getSubCategoriesData}
        messageApi={messageApi}
      />
      <Modal
        title="ADD"
        open={modal3}
        onCancel={() => {
          setAdd({});
          setModal3(false);
        }}
        onOk={addDataToSubCategory}
        confirmLoading={buttonLoading}
      >
        <div className="col2">
          {add?.type === "series" ? (
            <Select
              placeholder="Select Series"
              onChange={(value) => {
                setAdd({
                  ...add,
                  id: value,
                });
              }}
              options={series.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              value={add?.id}
            />
          ) : (
            <Select
              placeholder="Select Movie"
              onChange={(value) => {
                setAdd({
                  ...add,
                  id: value,
                });
              }}
              value={add?.id}
              options={movies
                .filter(
                  (item) =>
                    add?.content?.find((i) => i?.item?._id === item?._id) ===
                    undefined
                )
                .map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
            />
          )}

          <Input
            placeholder="Position"
            onChange={(e) => {
              setAdd({
                ...add,
                position: e.target.value,
              });
            }}
            value={add?.position}
          />
        </div>
      </Modal>
      <SubModal
        modal={modal}
        setModal={setModal}
        getSubCategoriesData={getSubCategoriesData}
        update={update}
        newSubCategory={newSubCategory}
        setNewSubCategory={setNewSubCategory}
        setUpdate={setUpdate}
        id={id}
      />
    </>
  );
};

export default SubCategories;
