import {
  Button,
  Checkbox,
  Image,
  Input,
  message,
  Modal,
  Table,
  Typography,
  Upload,
} from "antd";
import React from "react";
import Head from "../components/common/Head";
import { getAllGenres, getAllSeries } from "../utils/api";
import { UploadOutlined } from "@ant-design/icons";
import MoviesModal from "../components/movies/Modal";
import ContentTable from "../components/movies/Table";
import useValidate from "../hooks/useValidate";

const Series = () => {
  const [series, setSeries] = React.useState([]);
  const [newSeries, setNewSeries] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [update, setUpdate] = React.useState({});
  const [newGenre, setNewGenre] = React.useState([]);
  const [genres, setGenres] = React.useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [cast, setCast] = React.useState([]);
  const [casts, setCasts] = React.useState([]);

  const getAllGenresData = async () => {
    setLoading(true);
    const [success, response] = await getAllGenres();
    if (success) setGenres(response);
    setLoading(false);
  };

  useValidate();
  const getAllSeriesData = async () => {
    setLoading(true);
    const [success, response] = await getAllSeries();
    if (success) setSeries(response);
    setLoading(false);
  };

  React.useEffect(() => {
    getAllSeriesData();
    getAllGenresData();
  }, []);
  return (
    <>
      {contextHolder}
      <Head
        title="Series"
        button={"Add Series"}
        onClick={() => setModal(true)}
      />
      <ContentTable
        data={series}
        loading={loading}
        setModal2={setModal2}
        setUpdate={setUpdate}
        series
        messageApi={messageApi}
        reload={getAllSeriesData}
      />

      <MoviesModal
        modal={modal}
        setModal={setModal}
        series
        reload={getAllSeriesData}
        genres={genres}
        messageApi={messageApi}
      />
      <MoviesModal
        modal={modal2}
        setModal={setModal2}
        series
        reload={getAllSeriesData}
        genres={genres}
        update={update}
        messageApi={messageApi}
      />
    </>
  );
};

export default Series;
