import {
  Button,
  Image,
  Input,
  message,
  Modal,
  Select,
  Table,
  Upload,
} from "antd";
import React, { useState } from "react";
import Head from "../components/common/Head";
import {
  addUser,
  deleteUser,
  getAllSubscribtions,
  getAllUsers,
  getUserNotication,
  updateUserImage,
  updateUserSubscription,
  userSubHistory,
} from "../utils/api";
import { UploadOutlined } from "@ant-design/icons";
import UserModal from "../components/users/Modal";
import useValidate from "../hooks/useValidate";
import useSearch from "../components/common/useSearch";
const Users = () => {
  const [modal, setModal] = useState();
  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [update, setUpdate] = useState({});
  const [modal2, setModal2] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const search = useSearch();
  const getAllUserData = async () => {
    setLoading(true);
    const [success, response] = await getAllUsers();
    if (success) setUsers(response);
    setLoading(false);
  };

  const getUserNoticationData = async (id) => {
    setLoading2(true);
    const [success, response] = await getUserNotication(id);
    if (success) setNotifications(response);

    setLoading2(false);
    setModal3(true);
  };
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const getAllSubsHandler = async () => {
    const [success, data] = await getAllSubscribtions();
    if (success) {
      setAllSubscriptions(data);
    }
  };
  useValidate();
  React.useEffect(() => {
    getAllUserData();
    getAllSubsHandler();
  }, []);
  return (
    <>
      {contextHolder}
      <Head title={"Users"} button="Add User" onClick={() => setModal(true)} />
      <Table
        rowKey={(record) => record._id}
        loading={loading}
        scroll={{ x: 1000 }}
        dataSource={users?.reverse()}
        columns={[
          {
            title: "Actions",
            dataIndex: "Actions",
            key: "Actions",
            render: (text, record) => (
              <div className="col2">
                <AddSubscription
                  item={record}
                  reload={getAllUserData}
                  messageApi={messageApi}
                  subscription={allSubscriptions}
                />
                <Button
                  type="primary"
                  loading={loading2}
                  onClick={() => {
                    getUserNoticationData(record._id);
                  }}
                >
                  Notification
                </Button>
                <Button
                  type="primary"
                  onClick={async () => {
                    console.log(record);
                    const [success, response] = await userSubHistory(
                      record?._id
                    );
                    console.log(response);
                    if (success) {
                      Modal.info({
                        title: "Subscriptions History",
                        width: 800,
                        content: (
                          <Table
                            dataSource={response?.[0]?.history}
                            columns={[
                              {
                                title: "Subscription",
                                dataIndex: "subscription",
                                render: (text, record) => record,
                              },
                            ]}
                          />
                        ),
                      });
                    } else {
                      messageApi.error("Something went wrong");
                    }
                  }}
                >
                  Subscriptions History
                </Button>
                <Upload
                  customRequest={async ({ file, onSuccess }) => {
                    setLoading3(true);
                    const formData = new FormData();
                    formData.append("image", file);
                    const [success, response] = await updateUserImage(
                      record._id,
                      formData
                    );
                    if (success) {
                      messageApi.success("User image updated successfully");
                      getAllUserData();
                      onSuccess();
                    } else {
                      messageApi.error(response);
                    }
                    setLoading3(false);
                  }}
                  showUploadList={false}
                >
                  <Button
                    style={{
                      width: "100%",
                    }}
                    icon={<UploadOutlined />}
                    loading={loading3}
                  >
                    Update User Photo
                  </Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={() => {
                    setUpdate(record);
                    setModal2(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  danger
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure delete this task?",
                      content: "Please Confirm",
                      onOk: async () => {
                        const [success, response] = await deleteUser(
                          record._id
                        );
                        if (success) {
                          messageApi.success("User deleted successfully");
                          getAllUserData();
                        } else {
                          messageApi.error(response);
                        }
                      },
                    });
                  }}
                >
                  Delete
                </Button>
              </div>
            ),
          },
          {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (text) => (
              <Image
                src={text}
                width={50}
                height={50}
                style={{ borderRadius: "50%" }}
              />
            ),
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...search("name"),
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            ...search("email"),
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            ...search("phoneNumber"),
          },
          {
            title: "DOB",
            dataIndex: "dob",
            key: "dob",
          },

          {
            title: "Creation Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => new Date(text).toLocaleDateString(),
          },
        ]}
      />
      <UserModal
        modal={modal}
        setModal={setModal}
        reload={getAllUserData}
        messageApi={messageApi}
      />
      <UserModal
        modal={modal2}
        setModal={setModal2}
        reload={getAllUserData}
        messageApi={messageApi}
        update={update}
      />
      <Modal
        open={modal3}
        onCancel={() => setModal3(false)}
        title="Notification"
        footer={null}
        width={700}
      >
        <Table
          rowKey={(record) => record._id}
          dataSource={notifications}
          columns={[
            {
              title: "image",
              dataIndex: "image",
              key: "image",
              render: (text) => (
                <Image
                  src={text}
                  width={50}
                  height={50}
                  style={{ borderRadius: "50%" }}
                />
              ),
            },
            {
              title: "title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "description",
              dataIndex: "description",
              key: "description",
            },
            {
              title: "createdAt",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (text) => new Date(text).toLocaleDateString(),
            },
            {
              title: "read",
              dataIndex: "read",
              key: "read",
              render: (text) => (text ? "Read" : "Unread"),
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default Users;

const AddSubscription = ({ reload, messageApi, subscription, item }) => {
  const [currentSub, setCurrentSub] = useState("");
  const [loading, setLoading] = useState(false);
  const addSubscription = async () => {
    if (currentSub.length <= 0)
      return messageApi.error("Please select subscription");

    setLoading(true);
    const [success, response] = await updateUserSubscription({
      subscriptionId: currentSub,
      userId: item._id,
    });
    if (success) {
      setModal(false);
      messageApi.success("Subscription added successfully");
      reload();
    }
    setLoading(false);
  };
  const [modal, setModal] = useState(false);
  return (
    <>
      <Button onClick={() => setModal(true)} type="primary">
        Add Subscription
      </Button>

      <Modal
        confirmLoading={loading}
        title="Add Subscription"
        open={modal}
        onCancel={() => setModal(false)}
        onOk={addSubscription}
      >
        <Select
          value={currentSub}
          onChange={(value) => {
            setCurrentSub(value);
          }}
          style={{ width: "100%" }}
          placeholder="Select Subscription"
          options={subscription.map((item) => ({
            label: item.title,
            value: item._id,
          }))}
        ></Select>
      </Modal>
    </>
  );
};
