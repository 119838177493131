import { Input, message, Modal, Table } from "antd";
import React from "react";
import Head from "../components/common/Head";
import useValidate from "../hooks/useValidate";
import {
  addGenre,
  getAllActiveSubs,
  getAllGenres,
  getAllMovies,
  getAllSeries,
  getAllTransaction,
} from "../utils/api";
import useSearch from "../components/common/useSearch";

const AllSubsActive = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [genres, setGenres] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const search = useSearch();
  const getAllGenresData = async () => {
    setLoading(true);
    const [success, response] = await getAllActiveSubs();

    if (success) setGenres(response);
    setLoading(false);
  };
  console.log(genres);
  useValidate();
  React.useEffect(() => {
    getAllGenresData();
  }, []);
  return (
    <>
      {contextHolder}
      <Head title="Active Subscription" />

      <Table
        loading={loading}
        dataSource={genres}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            ...search("name"),
          },
          {
            title: "Email",
            dataIndex: "email",
            ...search("email"),
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            ...search("phoneNumber"),
          },
          {
            title: "Subscription Name",
            dataIndex: ["subscriptionType", "title"],
          },
          {
            title: "Subscription Buy Date",
            dataIndex: "subscriptionBuyDate",
            render: (text) => {
              return new Date(text).toLocaleDateString();
            },
          },
          {
            title: "Subscription Expire Date",
            dataIndex: "subscriptionExpireDate",
            render: (text) => {
              return new Date(text).toLocaleDateString();
            },
          },
        ]}
      />
    </>
  );
};

export default AllSubsActive;
