import { Button, Input, Modal, Upload } from "antd";
import React, { useState } from "react";
import { addUser, updateUser } from "../../utils/api";
import { UploadOutlined } from "@ant-design/icons";

const UserModal = ({ modal, setModal, messageApi, reload, update }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [newUser, setNewUser] = useState({});
  const addUsers = async () => {
    if (
      !newUser.name ||
      !newUser.email ||
      !newUser.dob ||
      !newUser.phoneNumber
    ) {
      messageApi.error("Please fill all the fields");
      return;
    }
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("name", newUser.name);
    formData.append("email", newUser.email);
    formData.append("dob", newUser.dob);
    formData.append("phoneNumber", newUser.phoneNumber);

    formData.append("image", newUser.image);

    if (!update) {
      const [success, response] = await addUser(formData);
      if (success) messageApi.success("User Added Successfully");
    } else {
      const [success, response] = await updateUser(update._id, {
        name: newUser.name,
        email: newUser.email,
        dob: newUser.dob,
        phoneNumber: newUser.phoneNumber,
      });
      if (success) messageApi.success("User Updated Successfully");
    }
    setModal(false);
    reload();
    setButtonLoading(false);
  };

  React.useEffect(() => {
    if (update) {
      
      setNewUser({
        name: update.name,
        email: update.email,
        dob: update.dob,
        phoneNumber: update.phoneNumber,
      });
    }
  }, [update]);
  return (
    <Modal
      open={modal}
      onCancel={() => setModal(false)}
      title={update ? "Update User" : "Add User"}
      onOk={() => {
        addUsers();
      }}
      confirmLoading={buttonLoading}
    >
      <div className="col">
        <Input
          placeholder="name"
          onChange={(e) =>
            setNewUser((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
          value={newUser.name}
        />
        <Input
          placeholder="email"
          onChange={(e) =>
            setNewUser((prev) => ({
              ...prev,
              email: e.target.value,
            }))
          }
          value={newUser.email}
        />
        <Input
          placeholder="phoneNumber"
          onChange={(e) =>
            setNewUser((prev) => ({
              ...prev,
              phoneNumber: e.target.value,
            }))
          }
          value={newUser.phoneNumber}
        />
        <Input
          type="date"
          onChange={(e) => {
            const date = new Date(e.target.value);
            let day = date.getDate();
            let month = date.getMonth() + 1;
            const year = date.getFullYear();
            if (day < 10) {
              day = "0" + day;
            }
            if (month < 10) {
              month = "0" + month;
            }
            setNewUser((prev) => ({
              ...prev,
              dob: day + "/" + month + "/" + year,
            }));
          }}
          value={newUser.dob ? newUser.dob.split("/").reverse().join("-") : ""}
        />
        {!update && (
          <Upload
            onChange={(e) =>
              setNewUser((prev) => ({
                ...prev,
                image: e.file.originFileObj,
              }))
            }
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        )}
      </div>
    </Modal>
  );
};

export default UserModal;
