import {
  Button,
  Checkbox,
  Input,
  Modal,
  Select,
  Typography,
  Upload,
  Switch,
} from "antd";
import React from "react";
import {
  addEpisode,
  addMovie,
  addSeries,
  addSoon,
  updateEpisode,
  updateMovie,
  updateSeries,
  updateSoon,
} from "../../utils/api";
import { UploadOutlined } from "@ant-design/icons";

const MoviesModal = ({
  modal,
  setModal,
  series,
  messageApi,
  reload,
  genres,
  update,
  episodes,
  id,
  coming,
}) => {
  const [buttonloading, setButtonLoading] = React.useState(false);
  const [newMovie, setNewMovie] = React.useState({});
  const [newGenre, setNewGenre] = React.useState([]);
  const [cast, setCast] = React.useState({
    name: "",
    image: "",
    role: "",
  });

  const [casts, setCasts] = React.useState([]);
  const addNewMovie = async () => {
    if (
      !newMovie.name ||
      !newMovie.description ||
      (!series && !coming && !newMovie.url) ||
      !newMovie.horizontalBanner ||
      !newMovie.verticalBanner ||
      (!series && !coming && !newMovie.duration)
    ) {
      messageApi.error("Please fill all the fields");
      return;
    }
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("name", newMovie.name);
    formData.append("description", newMovie.description);
    if (!series && !coming) {
      formData.append("url", newMovie.url);
      formData.append("urlType", newMovie.urlType);
      formData.append("duration", newMovie.duration);
    }
    if (coming) {
      formData.append("position", newMovie.position);
    }

    if (!episodes) {
      formData.append("trailer", newMovie.trailer);
      formData.append("genres", JSON.stringify(newGenre));
      if (!coming) {
        formData.append("cast", JSON.stringify(casts));
        formData.append("isPaid", false);
        formData.append("accessTime", newMovie.accessTime ?? 10);
        formData.append("isFree", false);
        formData.append("price", newMovie.price ?? 0);
      }
      formData.append("language", newMovie.language);
      formData.append("ageLimit", newMovie.ageLimit);

      formData.append("rating", newMovie.rating);

      formData.append("view", newMovie.view ?? 0);

      formData.append(
        "trailerType",
        newMovie.trailerType === undefined ? "Video" : newMovie.trailerType
      );
    } else {
      formData.append("seriesId", id);
      formData.append("episodeNumber", newMovie.episodeNumber);
      formData.append("rating", "");

      formData.append("language", "");
      formData.append("ageLimit", "");
      formData.append("genres", JSON.stringify([]));
      formData.append("cast", JSON.stringify([]));
      formData.append("trailer", "");
    }

    if (!update) {
      if (!episodes) {
        formData.append("isEnable", true);
      }
      formData.append("horizontalBanner", newMovie.horizontalBanner);
      formData.append("verticalBanner", newMovie.verticalBanner);

      const [success, response] = coming
        ? await addSoon(formData)
        : series
        ? await addSeries(formData)
        : episodes
        ? await addEpisode(formData)
        : await addMovie(formData);
      if (success) {
        messageApi.success(
          `${
            series ? "Series" : episodes ? "Episode" : "Movie"
          } created successfully`
        );
        setModal(false);
        setNewMovie({});
        setNewGenre([]);
        setCasts([]);
        reload();
      } else {
        messageApi.error(response.message);
      }
    } else {
      if (newMovie.horizontalBanner !== update.horizontalBanner) {
        formData.append("horizontalBanner", newMovie.horizontalBanner);
      }
      if (newMovie.verticalBanner !== update.verticalBanner) {
        formData.append("verticalBanner", newMovie.verticalBanner);
      }
      if (episodes) {
        formData.append("episodeId", update._id);
      }

      const [success, response] = coming
        ? await updateSoon(update._id, formData)
        : series
        ? await updateSeries(update._id, formData)
        : episodes
        ? await updateEpisode(formData)
        : await updateMovie(update._id, formData);

      if (success) {
        messageApi.success(
          `${
            series ? "Series" : episodes ? "Episode" : "Movie"
          } updated successfully`
        );
        setModal(false);

        reload();
      } else {
        messageApi.error(response.message);
      }
    }
    setButtonLoading(false);
  };

  React.useEffect(() => {
    if (update) {
      setNewMovie({
        name: update.name,
        description: update.description,
        url: update.url,
        horizontalBanner: update.horizontalBanner,
        verticalBanner: update.verticalBanner,
        duration: update.duration,
        rating: update.rating,
        language: update.language,
        ageLimit: update.ageLimit,
        trailer: update.trailer,
        isEnable: update.isEnable,
        // isPaid: update.isPaid,
        view: update.view,

        price: update.price,
        accessTime: update.accessTime,
        urlType: update.urlType,
        trailerType: update.trailerType,
        position: update.position,
      });
      setNewGenre(
        update?.genres?.map((genre) => {
          return genre._id;
        })
      );
      setCasts(update.cast);
    }
  }, [update]);

  return (
    <Modal
      open={modal}
      onCancel={() => setModal(false)}
      title={
        update
          ? `Update ${
              coming
                ? "Coming Soon"
                : series
                ? "Series"
                : episodes
                ? "Episode"
                : "Movie"
            }`
          : `Add ${
              coming
                ? "Coming Soon"
                : series
                ? "Series"
                : episodes
                ? "Episode"
                : "Movie"
            }`
      }
      onOk={addNewMovie}
      width={400}
      confirmLoading={buttonloading}
    >
      <div className="col">
        <Input
          placeholder="Name"
          onChange={(e) => setNewMovie({ ...newMovie, name: e.target.value })}
          value={newMovie.name}
        />
        <Input
          placeholder="Description"
          onChange={(e) =>
            setNewMovie({ ...newMovie, description: e.target.value })
          }
          value={newMovie.description}
        />
        {!series && !coming && (
          <>
            <Select
              placeholder="Select URL Type"
              onChange={(e) => setNewMovie({ ...newMovie, urlType: e })}
              value={newMovie.urlType}
              options={[
                { label: "Video", value: "Video" },
                { label: "Youtube", value: "Youtube" },
              ]}
            />
            <Input
              placeholder="url"
              onChange={(e) =>
                setNewMovie({ ...newMovie, url: e.target.value })
              }
              value={newMovie.url}
            />
            <Input
              placeholder="duration"
              onChange={(e) =>
                setNewMovie({ ...newMovie, duration: e.target.value })
              }
              value={newMovie.duration}
            />
          </>
        )}
        {coming && (
          <Input
            placeholder="Position"
            onChange={(e) =>
              setNewMovie({ ...newMovie, position: e.target.value })
            }
            value={newMovie.position}
          />
        )}

        {!episodes && (
          <>
            <Select
              placeholder="Select Trailer Type"
              onChange={(e) => setNewMovie({ ...newMovie, trailerType: e })}
              value={newMovie.trailerType}
              options={[
                { label: "Video", value: "Video" },
                { label: "Youtube", value: "Youtube" },
              ]}
            />

            <Input
              placeholder="trailer"
              onChange={(e) =>
                setNewMovie({ ...newMovie, trailer: e.target.value })
              }
              value={newMovie.trailer}
            />

            {!coming && (
              <>
                {" "}
                <Typography style={{ marginRight: 10 }}>
                  Rent Available
                </Typography>
                <Switch
                  checked={newMovie?.isPaid}
                  onChange={(e) => {
                    if (e === true) {
                      setNewMovie((prev) => ({
                        ...prev,
                        isFree: false,
                        isPaid: e,
                      }));
                    } else {
                      setNewMovie((prev) => ({
                        ...prev,
                        isPaid: e,
                      }));
                    }
                    console.log(e);
                  }}
                />
                <Typography style={{ marginRight: 10 }}>
                  Free Available
                </Typography>
                <Switch
                  checked={newMovie?.isFree}
                  onChange={(e) => {
                    if (e === true) {
                      setNewMovie((prev) => ({
                        ...prev,
                        isPaid: false,
                        isFree: e,
                      }));
                    } else {
                      setNewMovie((prev) => ({
                        ...prev,
                        isFree: e,
                      }));
                    }

                    console.log(e);
                  }}
                />
                {newMovie?.isPaid && (
                  <Input
                    placeholder="Access Time"
                    onChange={(e) =>
                      setNewMovie({ ...newMovie, accessTime: e.target.value })
                    }
                    value={newMovie.accessTime}
                  />
                )}
                {newMovie?.isPaid && (
                  <Input
                    placeholder="Price"
                    onChange={(e) =>
                      setNewMovie({ ...newMovie, price: e.target.value })
                    }
                    value={newMovie.price}
                  />
                )}
              </>
            )}

            <Select
              placeholder="Select Language"
              onChange={(e) => setNewMovie({ ...newMovie, language: e })}
              options={[
                { label: "Hindi", value: "Hindi" },
                { label: "English", value: "English" },
                { label: "Marathi", value: "Marathi" },
                { label: "Punjabi", value: "Punjabi" },
                { label: "Gujarati", value: "Gujarati" },
                { label: "Bengali", value: "Bengali" },
                { label: "Bhojpuri", value: "Bhojpuri" },
                { label: "Urdu", value: "Urdu" },
                { label: "Telugu", value: "Telugu" },
                { label: "Tamil", value: "Tamil" },
                { label: "Kannada", value: "Kannada" },
                { label: "Malayalam", value: "Malayalam" },
                { label: "Tulu", value: "Tulu" },
                { label: "Assamese", value: "Assamese" },
              ]}
              value={newMovie.language}
            />
            <Select
              placeholder="Rating"
              onChange={(e) => setNewMovie({ ...newMovie, rating: e })}
              value={newMovie.rating}
              options={[
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5", value: "5" },
              ]}
            />
            <Select
              placeholder="Age Limit"
              onChange={(e) => setNewMovie({ ...newMovie, ageLimit: e })}
              value={newMovie.ageLimit}
              options={[
                { label: "13+", value: "13+" },
                { label: "16+", value: "16+" },
                { label: "18+", value: "18+" },
                { label: "U", value: "U" },
                { label: "U/A", value: "U/A" },
                { label: "A", value: "A" },
              ]}
            />

            <Typography style={{ marginRight: 10 }}>Genres</Typography>
            <div className="genres">
              {genres?.map((item) => (
                <Checkbox
                  key={item._id}
                  checked={newGenre?.includes(item._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setNewGenre([...newGenre, item._id]);
                    } else {
                      setNewGenre(newGenre?.filter((i) => i !== item._id));
                    }
                  }}
                >
                  {item?.name}
                </Checkbox>
              ))}
            </div>
          </>
        )}

        {episodes && !update && (
          <Input
            placeholder="Episode-Number"
            onChange={(e) =>
              setNewMovie({ ...newMovie, episodeNumber: e.target.value })
            }
            value={newMovie.episodeNumber}
          />
        )}

        <Upload
          onChange={(e) =>
            setNewMovie({
              ...newMovie,
              horizontalBanner: e.file.originFileObj,
            })
          }
        >
          <Button icon={<UploadOutlined />}>Upload Horizonal Banner</Button>
        </Upload>
        <Upload
          onChange={(e) =>
            setNewMovie({
              ...newMovie,
              verticalBanner: e.file.originFileObj,
            })
          }
        >
          <Button icon={<UploadOutlined />}>Upload Vertical Banner</Button>
        </Upload>
      </div>
    </Modal>
  );
};

export default MoviesModal;
