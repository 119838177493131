import React from "react";
import {
  addDataToSubcription,
  addSubscribtion,
  deleteSubscription,
  getAllMovies,
  getAllSeries,
  getAllSubscribtions,
  subscribtionsCheckMovie,
  subscribtionsCheckSeries,
  updateSubscription,
} from "../utils/api";
import {
  AutoComplete,
  Button,
  Input,
  Modal,
  Select,
  Space,
  Table,
  message,
} from "antd";
import Head from "../components/common/Head";

const AddSubcription = () => {
  const [open, setOpen] = React.useState(false);
  const [movie, setMovie] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [ids, setIds] = React.useState([]);

  const getAllMoviesHandler = async () => {
    const [success, data] = await getAllMovies();
    if (success) {
      setMovie(data);
    }
  };

  const getAllSeriesHandler = async () => {
    const [success, data] = await getAllSeries();
    if (success) {
      setSeries(data);
    }
  };

  const [open2, setOpen2] = React.useState(false);

  const [data, setData] = React.useState({});
  const [update, setUpdate] = React.useState(false);
  const [add, setAdd] = React.useState({
    movie: false,
    series: false,
    id: "",
  });

  const [loading, setLoading] = React.useState(true);
  const [subscribtions, setSubscribtions] = React.useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const onCreateSubcription = async () => {
    setLoading(true);
    if (data.title && data.description && data.price && data.validity) {
      if (!update) {
        const [status, res] = await addSubscribtion(data);
        if (status) {
          messageApi.success("Subcription Created Successfully");
          getAllSubscribtionsHandler();
          setOpen(false);
        } else {
          messageApi.error("Subcription Not Created Successfully");
        }
      } else {
        const [status, res] = await updateSubscription(
          {
            title: data.title,
            description: data.description,
            price: data.price,
            validity: data.validity,
            unDiscountedPrice: data.unDiscountedPrice,
          },
          data._id
        );

        setOpen(false);
        getAllSubscribtionsHandler();
        setUpdate(false);
        setData({});
      }
    } else {
      messageApi.error("Please fill all the fields");
    }
    setLoading(false);
  };

  const getAllSubscribtionsHandler = async () => {
    setLoading(true);
    const [status, res] = await getAllSubscribtions();
    if (status) {
      setSubscribtions(res);
    } else {
      messageApi.error("Subscriptions Not Found");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllSubscribtionsHandler();
    getAllMoviesHandler();
    getAllSeriesHandler();
  }, []);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {contextHolder}
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {" "}
        <Head
          title="Subscriptions"
          onClick={() => {
            setData({});
            setUpdate(false);
            setOpen(true);
          }}
          button={"Add Subscription"}
        />
        <Table
          loading={loading}
          dataSource={subscribtions}
          columns={[
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle" direction="vertical">
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setOpen(true);
                      setUpdate(true);
                      setData(record);
                    }}
                  >
                    Edit Subcription
                  </Button>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={async () => {
                      console.log(record);
                      const [success, data] = await subscribtionsCheckMovie(
                        record._id
                      );
                      console.log(data);
                      if (success) {
                        setAdd({
                          movie: true,
                          series: false,
                          id: record._id,
                        });
                        setMovie(data);
                        setOpen2(true);
                      }
                    }}
                    sx={{
                      width: "100%",
                    }}
                  >
                    Add Movies
                  </Button>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={async () => {
                      const [success, data] = await subscribtionsCheckSeries(
                        record._id
                      );
                      console.log(data);
                      if (success) {
                        setSeries(data);
                        setAdd({
                          movie: false,
                          series: true,
                          id: record._id,
                        });
                        setOpen2(true);
                      }
                    }}
                    sx={{
                      width: "100%",
                    }}
                  >
                    Add Series
                  </Button>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    danger
                    onClick={async () => {
                      Modal.confirm({
                        title:
                          "Are you sure you want to delete this subscription?",

                        content: "This action cannot be undone.",
                        okText: "Delete",
                        okType: "danger",
                        cancelText: "Cancel",
                        onOk: async () => {
                          const [success, data] = await deleteSubscription(
                            record._id
                          );
                          if (success) {
                            messageApi.success(
                              "Subscription Deleted Successfully"
                            );
                            getAllSubscribtionsHandler();
                          } else {
                            messageApi.error(
                              "Subscription Not Deleted Successfully"
                            );
                          }
                        },
                        onCancel() {
                          console.log("Cancel");
                        },
                      });
                    }}
                  >
                    Delete
                  </Button>

                  {/* <DeleteModal
                        onClick={async () => {
                          setLoading(true);
                          await deleteAction(row._id, deleteSubscription);
                          getAllSubscribtionsHandler();
                          setUpdate(false);
                          setData({});
                          setLoading(false);
                        }}
                      /> */}
                </Space>
              ),
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
            {
              title: "Non Discounted Price",
              dataIndex: "unDiscountedPrice",
              key:"unDiscountedPrice"
            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",
            },
            {
              title: "Validity",
              dataIndex: "validity",
              key: "validity",
            },
            {
              title: "Movies Count",
              dataIndex: "moviesCount",
              key: "moviesCount",
            },
            {
              title: "Series Count",
              dataIndex: "seriesCount",
              key: "seriesCount",
            },
          ]}
        />
        {/* <CreateModal
            onClose={onCloseAdd}
            isOpen={isOpenAdd}
            title={add.movie ? "Add Movies" : "Add Series"}
            create={async () => {
              setLoading(true);
              const [status, res] = await addDataToSubcription({
                [add.movie ? "movieIds" : "seriesIds"]: ids,
                type: add.movie ? "Movie" : "Series",
                subscriptionId: add.id,
              });
              if (status) {
                toast({
                  title: "Data Added",
                  description: "Data Added Successfully",
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
                onCloseAdd();
                getAllSubscribtionsHandler();
              } else {
                toast({
                  title: "Data Not Added",
                  description: "Data Not Added Successfully",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              }
              setLoading(false);
            }}
          >
            <Select
              isMulti
              onChange={(e) => {
                setIds(e.map((e) => e.value));
              }}
              options={
                add.movie
                  ? movie?.map((movie) => ({
                      value: movie._id,
                      label: movie.name,
                    }))
                  : series.map((series) => ({
                      value: series._id,
                      label: series.name,
                    }))
              }
            />
          </CreateModal>
           */}
        <Modal
          open={open2}
          onCancel={() => {
            setOpen2(false);
            setAdd({});
            setMovie([]);
            setSeries([]);
            setIds([]);
          }}
          confirmLoading={loading}
          onOk={async () => {
            setLoading(true);
            const [status, res] = await addDataToSubcription({
              [add.movie ? "movieIds" : "seriesIds"]: ids,
              type: add.movie ? "Movie" : "Series",
              subscriptionId: add.id,
            });
            if (status) {
              messageApi.success("Data Added Successfully");
              setOpen2(false);
              setAdd({});
              setMovie([]);
              setIds([]);
              setSeries([]);
              getAllSubscribtionsHandler();
            } else {
              messageApi.error("Data Not Added Successfully");
            }
            setLoading(false);
          }}
          title={add.movie ? "Add Movies" : "Add Series"}
        >
          <Select
            mode="multiple"
            onChange={(e) => {
              console.log(e);
              setIds(e);
            }}
            value={ids}
            style={{ width: "100%" }}
            options={
              add.movie
                ? movie?.map((movie) => ({
                    value: movie._id,
                    label: movie.name,
                  }))
                : series.map((series) => ({
                    value: series._id,
                    label: series.name,
                  }))
            }
          />
        </Modal>
        <Modal
          open={open}
          onCancel={() => {
            setOpen(false);
            setUpdate(false);
            setData({});
          }}
          confirmLoading={loading}
          onOk={async () => {
            setLoading(true);
            if (update) {
              const [status, res] = await updateSubscription(data, data._id);
              console.log(status, res);
              if (status) {
                messageApi.success("Subcription Updated Successfully");
                getAllSubscribtionsHandler();
                setOpen(false);
                setUpdate(false);
                setData({});
              } else {
                messageApi.error("Subcription Not Updated Successfully");
              }
            } else {
              const [status, res] = await addSubscribtion(data);
              if (status) {
                messageApi.success("Subcription Created Successfully");
                getAllSubscribtionsHandler();
                setOpen(false);
                setUpdate(false);
                setData({});
              } else {
                messageApi.error("Subcription Not Created Successfully");
              }
            }
            setLoading(false);
          }}
          title={update ? "Update Subcription" : "Create Subcription"}
        >
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Input
              value={data.title}
              onChange={(e) =>
                setData((prev) => ({ ...prev, title: e.target.value }))
              }
              placeholder="Title"
            />
            <Input
              value={data.description}
              onChange={(e) =>
                setData((prev) => ({ ...prev, description: e.target.value }))
              }
              placeholder="Description"
            />
            <Input
              value={data.price}
              onChange={(e) =>
                setData((prev) => ({ ...prev, price: e.target.value }))
              }
              placeholder="Price"
            />
            <Input
              value={data.unDiscountedPrice}
              onChange={(e) =>
                setData((prev) => ({ ...prev, unDiscountedPrice: e.target.value }))
              }
              placeholder="Non Discounted Price"
            />

            <Input
              value={data.validity}
              onChange={(e) =>
                setData((prev) => ({ ...prev, validity: e.target.value }))
              }
              placeholder="Validity"
            />
          </Space>
        </Modal>
      </Space>
    </Space>
  );
};

export default AddSubcription;
