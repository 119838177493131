import { message } from "antd"
import React from "react";
import Head from "../components/common/Head";
import { getAllGenres, getAllMovies } from "../utils/api";
import MoviesModal from "../components/movies/Modal";
import ContentTable from "../components/movies/Table";
import useValidate from "../hooks/useValidate";

const Movies = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [movies, setMovies] = React.useState([]);
  const [genres, setGenres] = React.useState([]);
  const [update, setUpdate] = React.useState({});
  const [modal2, setModal2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [modal, setModal] = React.useState(false);
  const getAllMoviesData = async () => {
    setLoading(true);
    const [success, response] = await getAllMovies();
    if (success) setMovies(response);
    setLoading(false);
  };

  const getAllGenresData = async () => {
    setLoading(true);
    const [success, response] = await getAllGenres();
    if (success) setGenres(response);
    setLoading(false);
  };
  useValidate();
  React.useEffect(() => {
    getAllMoviesData();
    getAllGenresData();
  }, []);
  return (
    <>
      {contextHolder}
      <Head
        title="Movies"
        button={"Add Movie"}
        onClick={() => setModal(true)}
      />
      <ContentTable
        data={movies}
        loading={loading}
        setModal2={setModal2}
        messageApi={messageApi}
        reload={getAllMoviesData}
        setUpdate={setUpdate}
      />
      <MoviesModal
        genres={genres}
        modal={modal}
        setModal={setModal}
        reload={getAllMoviesData}
        messageApi={messageApi}
      />
      <MoviesModal
        genres={genres}
        modal={modal2}
        setModal={setModal2}
        reload={getAllMoviesData}
        messageApi={messageApi}
        update={update}
      />
    </>
  );
};

export default Movies;
