import { Button, Col, Row, Typography } from "antd";
import React from "react";

const Head = ({ title, button, onClick }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col className="gutter-row" flex={1}>
        <Typography.Title level={2} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      </Col>
      <Col
        className="gutter-row"
        flex={1}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {button && (
          <Button type="primary" onClick={onClick}>
            {button}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default Head;
