import { Input, message, Modal, Table } from "antd";
import React from "react";
import Head from "../components/common/Head";
import useValidate from "../hooks/useValidate";
import { addGenre, getAllGenres } from "../utils/api";

const Genres = () => {
  const [modal, setModal] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [genres, setGenres] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [newGenre, setNewGenre] = React.useState({});
  const getAllGenresData = async () => {
    setLoading(true);
    const [success, response] = await getAllGenres();
    
    if (success) setGenres(response);
    setLoading(false);
  };

  const addNewGenre = async () => {
    if (!newGenre.name) return;
    setButtonLoading(true);
    const [success, response] = await addGenre(newGenre);
    if (success) {
      messageApi.success("Genre added successfully");
      setModal(false);
      getAllGenresData();
    }
    setButtonLoading(false);
  };
  useValidate();
  React.useEffect(() => {
    getAllGenresData();
  }, []);
  return (
    <>
      {contextHolder}
      <Head
        title="Genres"
        button={"Add Genre"}
        onClick={() => setModal(true)}
      />
      <Modal
        confirmLoading={buttonLoading}
        open={modal}
        onCancel={() => setModal(false)}
        title="Add Genre"
        onOk={addNewGenre}
      >
        <div className="col">
          <Input
            placeholder="Genre Name"
            value={newGenre.name}
            onChange={(e) => setNewGenre({ ...newGenre, name: e.target.value })}
          />
        </div>
      </Modal>
      <Table
        loading={loading}
        dataSource={genres}
        columns={[
          {
            title: "Genre Name",
            dataIndex: "name",
            key: "name",
          },
        ]}
      />
    </>
  );
};

export default Genres;
