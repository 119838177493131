import { Button, Card, Form, Input, message, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../utils/api";
import useStore from "../utils/store";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const login = useStore((state) => state.login);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const loginUser = async (values) => {
    setLoading(true);
    const [success, data] = await loginAdmin(values);

    if (success) {
      login(data);
      messageApi.success("Login Successfull");
      navigate("/");
    } else {
      messageApi.error(data.message);
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {contextHolder}
      <Form
        className="login-card"
        name="basic"
        labelCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={loginUser}
        autoComplete="off"
      >
        <div className="login-card-header">
          <h1>Admin Login</h1>
        </div>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            placeholder="
          Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            placeholder="
          Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
